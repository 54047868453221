
.article_details_img {
    padding: 20px;
}

#babylonJS {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}

/* clip table contents avoiding additional scroll bar */
.products-table .ReactTable .rt-table {
    overflow-x: clip;
}

/* overwrite default behaviour (aligning last header right) */
.ReactTable .rt-thead .rt-th:last-child {
    text-align: left !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: rgba(247, 247, 247, 0.2) !important;
    cursor: pointer !important;
}

/* remove sorting arrows due to a bug in tanstack table 7.8.0
   arrows should removed with column property disableSortBy
   but is not working in version 7.8.0
   fix: using v8 or v7.7 (but conflicts with version required by the theme)
*/
.products-table .ReactTable .rt-thead .rt-th.-cursor-pointer:first-child > div:first-of-type:before,
.products-table .ReactTable .rt-thead .rt-th.-cursor-pointer:first-child > div:first-of-type:after,
.products-table .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(2) > div:first-of-type:before,
.products-table .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(2) > div:first-of-type:after,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(2) > div:first-of-type:before,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(2) > div:first-of-type:after,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(3) > div:first-of-type:before,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(3) > div:first-of-type:after,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(4) > div:first-of-type:before,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(4) > div:first-of-type:after,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(5) > div:first-of-type:before,
.scan-info .ReactTable .rt-thead .rt-th.-cursor-pointer:nth-child(5) > div:first-of-type:after {
    cursor: default;
    content: "";
    right: auto;
}

.scan-info div.-previous + div {
    visibility: hidden;
}

/* remove margin for columns with removed sorting */
.ReactTable .rt-thead .rt-th {
    margin: 0;
}

.ReactTable .rt-td {
    margin: 0 !important;
}


/* hide the arrows when input type is number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.loader {
    vertical-align: center;
    margin: auto; /* Added */
    float: none; /* Added */
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.card-loader {
    margin: 0;
    height: 90vh;
}

.w-50 {
    color: aliceblue !important;
}

.tridi-control-bar a:first-child {
    display: none;
}

.btn.uploadBtn {
    margin: 0;
    padding: 5px;
}

.btn.uploadBtn i.icon-upload {
    vertical-align: baseline;
    top: 0;
    margin-left: 5px;
}

.rt-tr {
    line-height: 3;
}

.dimensions table td input {
    display: inline-block;
    width: 50%;
}

.dimensions table td input + span {
    margin-left: 10px;
}

.dimensions button {
    float: right;
    margin-left: 10px;
}

.model {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-deck:first-child .card {
    margin-bottom: 25px;
}

.tridi-container {
    width: 100%;
}

/* hide details of scan list on smaller screens (they don't fit in a row) */
@media screen and (max-width: 1225px) {
    .product-details .scan-info .mode {
        display: none;
    }

    .product-details .scan-info .preset {
        display: none;
    }

    .product-details .scan-info .date-finished {
        text-align: center;
    }
}

/* hide upload buttons for client
   TODO: add client specific css */
.product-details .upload-to-ftp {
    display: none;
}

.product-details .upload-to-shop {
    display: none;
}

/* prevent too much padding on the left side of the scan list if viewing on smaller screens */
@media screen and (min-width: 768px) and (max-width: 1199.98px) {
    .main-panel .product-details .content.scan-info {
        padding-left: 0 !important;
    }
}

/* align pagination in a row (select for page and page size are hidden) */
.product-details #pagination_inline {
    display: inline;
}

.product-details #p_button {
    width: 40%;
    float: left;
    top: 5px;
}

.product-details .previous_span {
    margin: -50px;
}

.product-details #n_button {
    width: 40% !important;
    top: -40px !important;
    float: right !important;
}

.product-details .next_span {
    margin: -32px;
}

/* page count label (of n) should belong to the selected page */
.products-table .page-select {
    margin-right: 0.5rem !important;
}

/* add width to page count in pagination */
.products-table .span-with-page-count {
    width: 20%;
}

/* don't highlight selected page */
.products-table .page-select.react-select.info .react-select__control .react-select__value-container--has-value .react-select__single-value,
.products-table .page-select.react-select.info .react-select__control .react-select__value-container--has-value .react-select__multi-value__label {
    color: rgba(255, 255, 255, 0.7) !important;
}

/* add margin in case of scrollbar */
.ReactTable .-pagination {
    margin-top: 0.5rem;
}

/* move sorting arrows to the left */
.products-table .rt-th:not(:nth-child(-n+2)) .rt-resizable-header-content {
    padding-left: 2em !important;
}

.rt-resizable-header-content::before {
    left: 1em !important;
}

.rt-resizable-header-content::after {
    left: .5em !important;
}